import React from 'react';

const {compose, withProps, lifecycle} = require("recompose");
const {withScriptjs,} = require("react-google-maps");
const {StandaloneSearchBox} = require("react-google-maps/lib/components/places/StandaloneSearchBox");

const AddressTypeAhead = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyB8ArSqqlVtmV3v9wQ6CojprSUgAcBvK7Q&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div/>,
        containerElement: <div/>,
    }),
    lifecycle({
        componentWillMount() {
            const refs = {};

            this.setState({
                places: [],
                onSearchBoxMounted: ref => {
                    refs.searchBox = ref;
                },
                onPlacesChanged: (setValFun) => {
                    const places = refs.searchBox.getPlaces();
                    setValFun(places.length>0? places[0].formatted_address : "");
                    this.setState({
                        places,
                    });
                },
            })
        },
    }),
    withScriptjs
)(props => {
        return <div data-standalone-searchbox="">
            <StandaloneSearchBox
                ref={props.onSearchBoxMounted}
                bounds={props.bounds}
                onPlacesChanged={()=>props.onPlacesChanged(props.onChange)}
            >
                <input
                    id="event-location"
                    type="text"
                    placeholder={props.placeholder}
                    className={"form-control"}
                    onChange={(e)=>props.onChange(e.target.value)}
                    value={props.location}
                />
            </StandaloneSearchBox>
        </div>;
    }
);


export default AddressTypeAhead