import React from 'react';
import { FormGroup, Row, Button, Label, FormText, } from 'reactstrap';
import { Formik, Field, Form } from 'formik';
import DateTime from 'react-datetime';
import * as yup from 'yup';
import queryString from 'query-string';
import axios from 'axios';
import { navigate, useParams } from "@reach/router"

import PerformersMultipleSelect from './PerformersMultipleSelect';
import PerformerStylesMultipleSelect from './PerformerStylesMultipleSelect';
import EventTypesMultipleSelect from './EventTypesMultipleSelect';
import AddressTypeAhead from "./AddressTypeAhead";
import FormErrorMessage from './FormErrorMessage';
import TimeZonesSelect from "./TimeZonesSelect";

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input/input'
import { isValidPhoneNumber } from 'react-phone-number-input'

import 'react-datetime/css/react-datetime.css';
import InputGroup from "reactstrap/es/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const moment = require('moment');
require('moment-timezone');

const apiEndpoint = process.env.NODE_ENV === "production"? 'https://hubspot.gigroster.com/gigroster/1.0.0/leads' : 'http://localhost:3000/gigroster/1.0.0/leads';

const NewLeadForm = () => {
    const {query} = queryString.parseUrl(window.location.href);
    const parsedValues = query.values? JSON.parse(query.values) : {};
    const agent = query.agent || "bookings@gigroster.com";
    const {referral} = useParams();
    const defaultValues = {
        "customer": {
            "firstName": "",
            "lastName": "",
            "email": "",
            "phone": "",
            "address": {"city": "", "state": "", "zip": ""}

        },
        "event": {
            "location": "",
            "address": "",
            "date": "",
            "time": "",
            "timezone": moment.tz.guess(),
            "duration": "",
            "type": "",
            "comments": "",
            "budget": ""
        },
        "performers": [],
        "performerStyles": "",
        agent,
        referral
    };
    const initialValues = {...defaultValues, ...parsedValues};
    const validationSchema = yup.object().shape({
        event: yup.object().shape({
            date: yup.string().required("Required")
                .test('test-name', 'Must be a future date in the format MMM D, YYYY. For example: '+moment().add(1,'week').format('MMM D, YYYY'),
                        v => {return moment(v, 'MMM D, YYYY',true).isValid() && (moment().diff(moment(v,'MMM D, YYYY',true))<0);}),
            time: yup.string().matches(/\d+:\d+\s+([ap])m/,{ message: "Must be a valid time", excludeEmptyString: true }).required("Required"),
            timezone: yup.string().required("Required"),
            duration: yup.string().required("Required"),
            budget: yup.string().required("Required"),
            location: yup.string().required("Required"),
            type: yup.string().required("Required"),
            comments: yup.string()
        }),
        performers: yup.array().of(yup.object()).test(
            'performers',
            'Required',
            function test(value){ return this.parent.performerStyles || value}
        ),
        performerStyles: yup.string().test(
            'performerStyles',
            'Required if no GigRoster act is selected',
            function test(value){ return this.parent.performers.length>0 || value}
        ),
        customer: yup.object().shape({
            firstName: yup.string().required("Required"),
            lastName: yup.string().required("Required"),
            email: yup.string().email("Invalid email").required("Required"),
            phone: yup.string().required("Required").test('customer.phone', 'invalid phone number', v=> isValidPhoneNumber(v)),
            company: yup.string(),
            address: yup.object().shape({
                street: yup.string(),
                city: yup.string(),
                state: yup.string(),
                zip: yup.string()
            })
        })
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={(values, actions) => {
                values.event.datetime = moment.tz(values.event.date+' '+values.event.time,'MMM D, YYYY hh:mm a',values.event.timezone).toISOString();
                delete values.event.date;
                delete values.event.time;
                values.event.address = values.event.address || values.event.location;
                console.log(JSON.stringify(values, null, 2));
                axios.post(apiEndpoint, values)
                    .then(res => {
                        console.log(res.data);
                        actions.setSubmitting(false);
                        actions.setStatus({success: true, message: "Saved!"});
                        navigate("/success");
                    })
                    .catch(error => {
                        actions.setSubmitting(false);
                        actions.setStatus({success: false, message: error.message});
                    });
            }}
            render={({values, errors, status, touched, isSubmitting, handleChange, setFieldValue}) => (
                <Form className="request-info mt-2 mb-5">
                    <Row form>
                        <h3>Tell Us About {referral ? 'The' : 'Your'} Event</h3>
                    </Row>
                    <div className="form-row">
                        <FormGroup className="col-md-3">
                            <label htmlFor="event-date"> Event Date </label>
                            <DateTime defaultValue={values.event.date}
                                      dateFormat= 'MMM D, YYYY'
                                      timeFormat={false}
                                      onChange={(t) => {setFieldValue("event.date", moment.isMoment(t)? t.format("MMM D, YYYY") : t);}}
                                      inputProps={{id: "event-date",className: "form-control",autoComplete: "off"}}
                            />
                        <FormErrorMessage name="event.date"/>
                        </FormGroup>
                        <FormGroup className="col-md-2">
                            <label htmlFor="event-time"> Event Time</label>
                            <DateTime defaultValue={values.event.time}
                                      dateFormat={false}
                                      timeFormat={'h:mm a'}
                                      onChange={(t)=> {setFieldValue("event.time", moment.isMoment(t)? t.format('h:mm a'): t);}}
                            />
                            <FormErrorMessage name="event.time"/>
                        </FormGroup>
                        <FormGroup className="col-md-3">
                            <label htmlFor="event-timezone">Timezone</label>
                            <TimeZonesSelect timezones={moment.tz.names()}
                                             defaultSelected={values.event.timezone}
                                             onChange={(v)=>setFieldValue('event.timezone', v)}
                            />
                            <FormErrorMessage name="event.timezone"/>
                        </FormGroup>
                        <FormGroup>
                        </FormGroup>
                        <FormGroup className="col-md-4">
                            <Label for="event-duration"> Event Duration</Label>
                            <Field type="text" id="event-duration" name="event.duration"
                                   render={({field})=>(
                                       <InputGroup>
                                           <input className="form-control" type="number" min={0} max={24}
                                                  value={moment.duration(values.event.duration).hours() > 0 ? moment.duration(values.event.duration).hours() : ""}
                                                  onChange={(e)=>{
                                                      const h = Number(e.target.value);
                                                      const m = moment.duration(values.event.duration).minutes();
                                                      const newDuration= moment.duration({hours:h,minutes: m});
                                                      setFieldValue("event.duration", newDuration.toISOString())}}
                                           />
                                           <div className="input-group-append">
                                               <span className={"input-group-text"}><small>hours</small></span>
                                           </div>
                                           <select className="custom-select" aria-label="duration minutes"
                                                   value={moment.duration(values.event.duration).minutes()}
                                                   onChange={
                                                       (e) => {
                                                           const h = moment.duration(values.event.duration).hours();
                                                           const m = Number(e.target.value);
                                                           const newDuration = moment.duration({hours: h, minutes: m});
                                                           setFieldValue("event.duration", newDuration.toISOString())
                                                       }}
                                           >
                                           <option value={0}>0</option>
                                           <option value={15}>15</option>
                                           <option value={30}>30</option>
                                           <option value={45}>45</option>
                                       </select>
                                           <div className="input-group-append">
                                               <span className={"input-group-text"}><small>minutes</small></span>
                                           </div>
                                       </InputGroup>
                                   )}
                            />
                            <FormErrorMessage name="event.duration" />
                        </FormGroup>
                    </div>
                    <FormGroup>
                        <Label for={"event-budget"}>Budget</Label>
                        <Field type="text" id={"event-budget"} name="event.budget" className="form-control"
                               placeholder="Maximum budget or a range"
                        />
                        <FormErrorMessage name="event.budget" />
                        <FormText color="muted">
                            Please let us know the maximum budget or the budge range you would like
                            to stay within for entertainment at your event.
                        </FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label for={"event-location"}>Event Location </Label>
                        <AddressTypeAhead location={values.event.location} onChange={(v) => setFieldValue("event.location", v)}
                                          placeholder="Type in the event location or venue address"
                        />
                        <FormErrorMessage name="event.location" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="event-type"> Event Type </Label>
                        <EventTypesMultipleSelect defaultSelected={values.event.type.split(/\s*(?:,|$)\s*/)}
                                                  onChange={(items) => {setFieldValue("event.type", items.join(', '))}}
                                                  apiURL={apiEndpoint+ '/event/types'}
                                                  placeholder="Music styles or event functions"
                        />
                        <FormErrorMessage name="event.type" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="performers">Which GigRoster Act Are You Interested In? <small>(optional if Entertainment Styles is filled below)</small></Label>
                        <PerformersMultipleSelect onChange={(v) => setFieldValue("performers", v)}
                                                  defaultSelected={values.performers}
                                                  placeholder="Names of GigRoster.com performers"
                        />
                        <FormErrorMessage name="performers" />
                        <FormText color="muted">
                            You may choose more than one. If not listed on GigRoster you can request information in the field below.
                        </FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label for="performer-styles">Entertainment Styles <small>(optional if GigRoster Act is selected above)</small> </Label>
                        <PerformerStylesMultipleSelect defaultSelected={values.performerStyles.split(/\s*(?:,|$)\s*/)}
                                                       onChange={(items) => {
                                                           setFieldValue("performerStyles", items.join(', '))
                                                       }}
                                                       placeholder="Styles of entertainment"
                        />
                        <FormErrorMessage name="performerStyles" />
                        <FormText color="muted">
                            Please let us know what styles of entertainment you are interested in or you may request
                            information on groups not listed on GigRoster.com.
                        </FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label for={"comments"}>Comments </Label>
                        <Field component="textarea" className="form-control" id={"comments"} name="event.comments"/>
                        <FormErrorMessage name="event.comments" />
                    </FormGroup>
                    <Row form>
                        {referral ? (
                            <h3>Let Us Know Who To Contact With Prices and Availability</h3>
                        ) : (
                            <h3>Let Us Know How To Contact You</h3>
                        )}
                    </Row>
                    <Row form>
                        <FormGroup className={"col-md-6"}>
                            <Label for={"first-name"}>First Name</Label>
                            <Field type={"text"} id="first-name" className="form-control" name="customer.firstName"/>
                            <FormErrorMessage name="customer.firstName" />
                        </FormGroup>
                        <FormGroup className={"col-md-6"}>
                            <Label for="last-name">Last Name</Label>
                            <Field type="text" id="Last-name" className="form-control" name="customer.lastName"/>
                            <FormErrorMessage name="customer.lastName" />
                        </FormGroup>
                    </Row>
                    <Row form>
                        <FormGroup className={"col-md-6"}>
                            <Label for={"email"}>Email</Label>
                            <Field type={"email"} id={"email"} className="form-control" name="customer.email"/>
                            <FormErrorMessage name="customer.email" />
                        </FormGroup>
                        <FormGroup className={"col-md-6"}>
                            <Label for={"phone"}>Phone</Label>
                            <PhoneInput
                                id={"phone"}
                                className="form-control"
                                country="US"
                                value={values.customer.phone}
                                onChange={value => setFieldValue("customer.phone", value)}
                            />
                            <FormErrorMessage name="customer.phone" />
                        </FormGroup>
                    </Row>
                    <FormGroup>
                        <Label for={"company"}>Company <small>(optional)</small></Label>
                        <Field type={"text"} id={"compnay"} className="form-control" name="customer.company"/>
                        <FormErrorMessage name="customer.company" />
                    </FormGroup>
                    <FormGroup>
                        <Label for={"customer.address"}>Address <small>(optional)</small></Label>
                        <Field type={"text"} id={"customer.address"} className="form-control"
                               name="customer.address.street"/>
                    </FormGroup>
                    <Row form>
                        <FormGroup className={"col-md-6"}>
                            <Label for={"customer.address-city"}>City</Label>
                            <Field id={"customer.address-city"} type={"text"} className="form-control"
                                   name="customer.address.city"/>
                        </FormGroup>
                        <FormGroup className={"col-md-3 col-sm-6"}>
                            <Label for={"customer.address-state"}>State</Label>
                            <Field id={"customer.address-state"} type={"text"} className="form-control"
                                   name="customer.address.state"/>
                        </FormGroup>
                        <FormGroup className={"col-md-3 col-sm-6"}>
                            <Label for={"customer.address-zip"}>Zip</Label>
                            <Field id={"customer.address-zip"} type={"text"} className="form-control"
                                   name="customer.address.zip"/>
                        </FormGroup>
                    </Row>
                    <FormErrorMessage name="customer.address" />
                    <Button type="submit" disabled={isSubmitting}>Request Info</Button>
                    {!!status &&
                        (status.success?
                            <div className="text-success"><FontAwesomeIcon icon={faCheckCircle}/> {status.message}</div>
                            : <div className="text-danger"><FontAwesomeIcon icon={faExclamationCircle}/> {status.message}</div>)
                    }
                </Form>
            )}
        />
    );
};

export default NewLeadForm