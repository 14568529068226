import React, { Component, Fragment } from 'react';
import Select from 'react-select';

class TimeZonesSelect extends Component {
    constructor(props){
        super(props);
        this.state = {options: []};
    }

    componentDidMount() {
        this.loadOptions();
    }

    loadOptions() {
        let opt = this.props.timezones;
        opt = opt.map((x,i) => { return  {value: i ,label:x}});
        this.setState({options: opt});
    }

    render() {
        return (
            <Fragment>
                <Select
                    id="time-zones"
                    isClearable
                    isMulti={false}
                    options={this.state.options}
                    onChange={(selected)=>{(selected ||[]).length>0 && this.props.onChange(selected[0].label)}}
                    defaultValue={{value: this.props.defaultSelected, label: this.props.defaultSelected}}
                />
            </Fragment>)
    }
}

export default TimeZonesSelect;