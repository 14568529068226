import React, {Component, Fragment} from 'react';
import AsyncSelect from 'react-select/async';

class PerformersMultipleSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {options: []};
    }

    componentDidMount() {
        this.setState({options:this.props.defaultSelected});
    }

    render() {
        return (
            <Fragment>
                <AsyncSelect
                    loadOptions={(query) => {
                        return fetch(`https://hubspot.gigroster.com/gigroster/1.0.0/search/listings?term=${query}`)
                            .then(resp => resp.json())
                            .then(json => json.map(x => {return {value:x.id, label: x.name}}));
                    }}
                    onChange={
                        (selected)=>{
                            const options = selected || [];
                            this.props.onChange(options.map(x=> {return {id: x.value, name: x.label}}));
                        }
                    }
                    id="performer-styles"
                    placeholder={this.props.placeholder}
                    cacheOptions={false}
                    isMulti
                    value={this.props.defaultSelected.map(x=> {return {value: x.id, label: x.name}})}
                    defaultOptions={true}
                    noOptionsMessage={ msg => msg.inputValue + " does not exist"}
                />
            </Fragment>)
    }
}

export default PerformersMultipleSelect;