import React, {Component} from 'react';
import { Router } from "@reach/router"
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import RequestInfoForm from "./components/RequestInfoForm";
import SuccessMessage from "./components/SuccessMessage";

class App extends Component {
    render() {
        return (
            <div className="App">
                <header className="App-header">
                </header>
                <div className="container-fluid">
                    <Router>
                        <RequestInfoForm  path="/"/>
                        <RequestInfoForm path="/ref/:referral"/>
                        <SuccessMessage path="/success"/>
                    </Router>
                    
                </div>
                <img src="https://www.facebook.com/tr?id=309279909516838&amp;ev=Contact" 
                height="1" width="1" style={{display: "none"}} alt="facebook pixel"/>
            </div>
            
        );
    }
}

export default App;
