import React from 'react';
import { connect, getIn} from 'formik';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

const FormErrorMessage = props=>{
    const error = getIn(props.formik.errors, props.name);
    const touch = getIn(props.formik.touched, props.name);
    const message = <small><div className="text-danger"><FontAwesomeIcon icon={faExclamationCircle}/> {error}</div></small>;
    return touch && error ? message : null;
};

export default connect(FormErrorMessage);