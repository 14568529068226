import React, { Component, Fragment } from 'react';
import CreatableSelect from "react-select/creatable/dist/react-select.esm";

class EventTypesMultipleSelect extends Component {
    constructor(props){
        super(props);
        this.state = {options: []};
    }

    componentDidMount() {
        this.loadOptions();
    }

    async loadOptions() {
        let opt = await fetch(this.props.apiURL).then(resp => resp.json());
        opt = opt.map((x,i) => { return  {value: i ,label:x}});
        this.setState({options: opt});
    }

    render() {
        return (
            <Fragment>
                <CreatableSelect
                    id="performer-styles"
                    isClearable
                    isMulti
                    options={this.state.options}
                    placeholder={this.props.placeholder}
                    onChange={(selected)=>{this.props.onChange((selected||[]).map(x=>x.label))}}
                    defaultValue={this.props.defaultSelected.map(x => {return {value: x, label: x}})}
                />
            </Fragment>)
    }
}

export default EventTypesMultipleSelect;