import React from 'react';
import { Link } from "@reach/router"

const SuccessMessage = () => {
    return <div className="jumbotron mt-5">
        <h2>Thank You</h2>
        <p className="lead">
            We've received your requests and one of our talented real people will start working 
            on getting information for you. 
        </p>        
        <p>
            Because a computer search can only answer so many questions when it comes to finding the best 
            real entertainment for your event!
        </p>
        <Link to="/" className="btn btn-primary btn-lg mr-2" >Request Information on Another Event</Link>
        <a href="https://www.gigroster.com" className="btn btn-primary btn-lg">Search More Talent</a>
    </div>
}

export default SuccessMessage